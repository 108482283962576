<template>
  <v-row class="colcenter">
   
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">

    </v-col>
    <v-col cols="11" sm="11" md="11" lg="8" xl="8" 
      class="fondo-seguros colcenter  mb-5">
      <v-row class="colcenter" >
        <v-col cols="12" sm="12" md="11" lg="10" xl="10" class="colcenter cuadros " >
          <v-row class="colcenter">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="seg-desgravamen-aviso1 colcenter">
                PREPAGO DE CREDITO
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
              <div class="seg-desgravamen-aviso3 colleft">
                Si portaste tu seguro de desgravamen asociado a crédito a través de Mueve Seguro y refinanciaste o prepagaste el crédito debes realizar los siguientes pasos para gestionar tu solicitud de renuncia:
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
              <div class="seg-desgravamen-aviso2 colleft">
                &#10003; Enviar solicitud de renuncia a través de correo electrónico a servicioalcliente@mueveseguro.cl
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
              <div class="seg-desgravamen-aviso2 colleft">
                &#10003; Indicar en tu solicitud lo siguiente:
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="seg-desgravamen-aviso2 colcenter">
                <ul>
                  <li>Nombre completo</li>
                  <li>Rut</li>
                  <li>N° de operación de crédito del cual prepagó o reliquidó</li>
                  <li>Datos de transferencia bancaria para realizar devolución de primas no consumidas</li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
              <div class="seg-desgravamen-aviso2 colleft">
                &#10003; Finalmente adjuntar en correo respaldo del prepago o reliquidación del crédito
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
              <div class="seg-desgravamen-aviso3 colleft">
                Por nuestra parte realizaremos la solicitud a la compañía y en un plazo no mayor a 10 días hábiles
tendrás tu devolución de prima en la cuenta que nos informaste.
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter">
          
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter">
          <v-row class="colcenter">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="volver"><span><span
                    style="color:white">VOLVER</span></span></v-btn>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PrivadoSeguros",
  data() {
    return {
     
    };
  },
  props: {
    montoSaldos: {},
  },
  methods: {
    volver() {
      this.$root.$refs.HomePrivado.cambiaMenu(1);
    },
  
  created() {
    this.$root.$refs.PrivadoSeguros = this;
  }
  }
};
</script>
<style scoped>
.divide {
  display: inline-flex;
}

.sm-size {
  width: 50%;
}

.colcenter {
  display: flex;
  justify-content: center;
}
.colleft {
  display: flex;
  justify-content: left;
}

.imagenBienvenido {
  background-color: white;
  border-radius: 40px;
}

.bannerBienvenido {
  padding: 0px;
}

.rectangle-432 {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  flex-shrink: 0;
  width: 90%;
  height: 270px;
  position: relative;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cuadros {
  padding: 10px;
}
.aviso-cuadro{
  background-color: black; 
  border-radius: 20px;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
    top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 42px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.si-tienes-otros-cr-ditos-de-consumo-automotriz-o-comercial-pyme-accede-a-contratar-seguro-para-portarlo-y-recuperar-dinero {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 1000%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

.txtbnt7 {
  font-size: 26px !important;
  font-weight: 500;
  padding: 28px;
  /* padding-top: 28px !important;
  padding-bottom: 28px !important; */
  /* background-color: rgb(3 75 178) !important;  */
  color: rgba(4, 19, 127, 1) !important;

}

.txtbnt6 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: white !important;
  /* color: white !important; */
  text-transform: unset !important;

}

.aviso {
  height: 280px;
}



@media only screen and (max-width: 1000px) {
  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
    font-size: 17px !important;
  }

  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
    font-size: 23px !important;
  }
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 35px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1 {
  border-radius: 0px 40px 40px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* height: 220px; */
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.seg-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  /* font-size: var(--headings-h3-font-size, 40px);
  line-height: var(--headings-h3-line-height, 48px); */
  font-size: clamp(1rem, 4vw, 2.2rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
  /* height: 40px; */
}

.seg-desgravamen-aviso1 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: clamp(1rem, 4vw, 2.2rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
}

.seg-desgravamen-aviso2 {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: clamp(0.8rem, 4vw, 1.2rem);
  line-height: clamp(0.8rem, 4vw, 1.7rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
}
.seg-desgravamen-aviso3 {
  color: var(--azul-complementario, #04137f);
  text-align: justify;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: clamp(0.8rem, 4vw, 1.2rem);
  line-height: clamp(0.8rem, 4vw, 1.7rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
}

.vector6 {
  width: 33px;
  height: 33px;
  position: relative;
  overflow: visible;
  align-self: center;
  margin-right: 10px;
  margin-top: -4px;
}

.fondo-seguros {
  background: var(--azul-claro, #ffffff);
  border-radius: 40px;
  padding: 40px 10px 40px 10px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  border : solid 1px;
}

.fondo-seguros-aviso {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 40px;
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.beneficiario {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 27px); */
  font-size: clamp(0.8rem, 4vw, 2rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
}

.banco {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: "CircularPro-Black", sans-serif;
  /* font-size: 36px;
  line-height: 27px; */
  font-size: clamp(0.8rem, 4vw, 1.6rem);
  line-height: clamp(0.8rem, 4vw, 1.6rem);
  font-weight: 800;
  position: relative;
}

.colleft {
  display: flex;
  justify-content: left;
}

.fecha-inicio {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
}

.fecha-inicio-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.fechadetalle {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 100px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  width: 100%;
  /* height: 69px; */
  position: relative;
  padding: 3px 20px 3px 20px;

}

@media only screen and (max-width: 800px) {
  .divide {
    display: none !important;
  }

  .sm-size {
    font-size: small !important;
    width: 100%;
  }

  .vector6 {
    margin-right: 10px;
  }

}

@media only screen and (max-width: 1270px) {
  .divide {
    display: none !important;
  }

}
</style>
