<template>
  <v-app>
    <div class="bannerlogo">
      <Nav></Nav>
      <div>

      </div>
      <div>
        <v-row class="colcenter">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
          <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colcenter"></v-col>
          <v-col cols="8" sm="8" md="2" lg="2" xl="2" class="colcenter">
            <div class="formulario-cotiza">
              <div class="frame-12">
                <div class="group-25">
                  <div class="group-20">
                    <img class="vector" v-if="estadoFormCotizacion >= 1" src="/assets/cotizacion/vector0.svg">
                    <img class="vector" v-if="estadoFormCotizacion < 1" src="/assets/cotizacion/vector1.svg">
                    <div class="_1">1</div>
                  </div>
                </div>
                <div class="group-26">
                  <div class="group-23">
                    <img class="vector2" v-if="estadoFormCotizacion >= 2" src="/assets/cotizacion/vector0.svg">
                    <img class="vector2" v-if="estadoFormCotizacion < 2" src="/assets/cotizacion/vector1.svg">
                    <div class="_2">2</div>
                  </div>
                </div>
                <div class="group-27">
                  <div class="group-24">
                    <img class="vector3"
                      v-if="estadoFormCotizacion >= 5 && estadoFormCotizacion != 17 && estadoFormCotizacion != 18 && estadoFormCotizacion != 19 && estadoFormCotizacion != 20"
                      src="/assets/cotizacion/vector0.svg">
                    <img class="vector3"
                      v-if="estadoFormCotizacion < 5 || estadoFormCotizacion == 17 || estadoFormCotizacion == 18 || estadoFormCotizacion == 19 || estadoFormCotizacion == 20"
                      src="/assets/cotizacion/vector1.svg">
                    <div class="_3">3</div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colleft asistente">
            <!-- <v-btn class="sm-size" rounded large color="juanito" @click="asistencia">
              <v-icon left style="color: white !important;">
                mdi-phone
              </v-icon>
              <span><span style="color:white">Asistencia Telefónica</span></span></v-btn> -->
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <v-row class="colcenter">
              <v-col cols="12" sm="12" md="8" lg="7" xl="6" class="colcenter formstyle">
                <div class="formulario-cotiza-persona-1">
                  <v-row class="colcenter">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 1">¡Hola!</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 2">¡Hola!</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 3">Por favor sigue rellenando los
                        siguientes datos</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 4">Por favor sigue rellenando los
                        siguientes datos</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 5 && !clienteEncontrado">¡Gracias por
                        registrarte!</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 5 && clienteEncontrado">¡Ahora solo
                        falta que ingreses a tu cuenta!</div>
                      <div class="hola-nombre-test" v-if="estadoFormCotizacion == 15">¡Buenas Noticias!</div>
                      <div class="hola-nombre-test"
                        v-if="(estadoFormCotizacion == 17 || estadoFormCotizacion == 18) && isLogin">Antecedentes de
                        respaldo</div>
                      <div class="hola-nombre-test" v-if="(estadoFormCotizacion == 19) && isLogin">Selecciona documentos
                        o
                        imágenes desde tu computador que contengan la información requerida</div>
                      
                      <div class="hola-nombre-test" v-if="(estadoFormCotizacion == 20) && isLogin">Firma electrónica de
                        documentos</div>
                      <div class="hola-nombre-test" v-if="(estadoFormCotizacion == 21) && isLogin">Terminos y
                        condiciones
                      </div>
                      <div class="hola-nombre-test" v-if="(estadoFormCotizacion == 25) && isLogin">¡Felicitaciones!
                      </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="estadoFormCotizacion <= 5">
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion < 3">
                        Te damos las bienvenida, selecciona si eres persona natural o empresa
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion >= 3 && estadoFormCotizacion <= 4">
                        Es importante que tengas tu cédula vigente para poder continuar con el proceso.
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 5 && !clienteEncontrado">
                        Ahora te enviamos un correo para que crees tu clave de acceso y continuar la contratación que te
                        permitirá recuperar dinero
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 5 && clienteEncontrado">
                        Para poder ver tu cotización, por favor ingresa a tu sitio privado
                      </div>
                     
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="(estadoFormCotizacion == 15 || estadoFormCotizacion == 19 || estadoFormCotizacion == 17 || estadoFormCotizacion == 18) && isLogin">
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa">
                      <img  class="videollamada" @click="llamada" src="https://statics.digitalf2f.com/widget/assets/integraciones/images/btn-mueveseguro-desktop.png">
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                      <div class="line-1"></div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 5 && !clienteEncontrado">
                        Si no recibiste el correo, haz click aquí
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 5 && clienteEncontrado">
                        haz click aquí
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 17 && isLogin">
                        Carga las imágenes de tu cedula de identidad por ambos lados
                      </div>
                      <div class="te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa"
                        v-if="estadoFormCotizacion == 18 && isLogin">
                        Adjunta Documentos que respalden el Crédito y Seguro Vigentes:
                      </div>


                      <div class="frame-32" v-if="estadoFormCotizacion <= 4">
                        <v-row class="colcenter" v-if="estadoFormCotizacion < 3">
                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn plain rounded @click="seleccionTipoUsuario(`persona`)"
                              :class="{ 'personaBtn': tipoPersonaEmpresa == 'persona', 'empresaBtn2': tipoPersonaEmpresa == 'empresa' }"><span
                                :class="{ 'personaBtn-s': tipoPersonaEmpresa == 'persona', 'personaBtn2-s': tipoPersonaEmpresa == 'empresa' }">PERSONA</span></v-btn>
                          </v-col>
                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn plain rounded @click="seleccionTipoUsuario(`empresa`)"
                              :class="{ 'empresaBtn': tipoPersonaEmpresa == 'empresa', 'empresaBtn2': tipoPersonaEmpresa == 'persona' }"><span
                                :class="{ 'empresaBtn-s': tipoPersonaEmpresa == 'empresa', 'empresaBtn2-s': tipoPersonaEmpresa == 'persona' }">EMPRESA</span></v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="colcenter" v-if="estadoFormCotizacion >= 3 && estadoFormCotizacion <= 4">
                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn plain rounded @click="seleccionTipo(`vigente`)" class="sm-size"
                              :class="{ 'personaBtn': tipoCotizacion == 'vigente', 'empresaBtn2': tipoCotizacion == 'nuevo' }"><span
                                :class="{ 'personaBtn-s': tipoCotizacion == 'vigente', 'personaBtn2-s': tipoCotizacion == 'nuevo' }">CRÉDITO
                                VIGENTE</span></v-btn>
                          </v-col>
                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn plain rounded @click="seleccionTipo(`nuevo`)" class="sm-size"
                              :class="{ 'empresaBtn': tipoCotizacion == 'nuevo', 'empresaBtn2': tipoCotizacion == 'vigente' }"><span
                                :class="{ 'empresaBtn-s': tipoCotizacion == 'nuevo', 'empresaBtn2-s': tipoCotizacion == 'vigente' }">CRÉDITO
                                NUEVO</span></v-btn>
                          </v-col>
                        </v-row>

                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"
                      :class="{ fullh: estadoFormCotizacion == 15 }">
                      <Form1Mo :paises="paises" :genero="genero" :ocupaciones="ocupacion"
                        v-show="(estadoFormCotizacion == 1 && (tipoPersonaEmpresa == `persona` || tipoPersonaEmpresa == `inicio`))">
                      </Form1Mo>
                      <Form2Mo :ocupaciones="ocupacion"
                        v-show="(estadoFormCotizacion == 2 && tipoPersonaEmpresa == `persona`)"></Form2Mo>
                      <Form3Mo :tipo="tipoCotizacion" v-if="cargaRestricciones"
                        v-show="(estadoFormCotizacion == 3 && tipoCotizacion)"></Form3Mo>
                      <Form4Mo :tipo="tipoCotizacion" v-if="cargaRestricciones"
                        v-show="(estadoFormCotizacion == 4 && tipoCotizacion)"></Form4Mo>
                      <FormE1Mo v-show="estadoFormCotizacion == 1 && tipoPersonaEmpresa == `empresa`"></FormE1Mo>
                      <FormE2Mo :paises="paises" :genero="genero" :ocupaciones="ocupacion"
                        v-show="estadoFormCotizacion == 2 && tipoPersonaEmpresa == `empresa`"></FormE2Mo>
                      <PropuestaMo :tipo="tipoCotizacion" v-if="estadoFormCotizacion == 15"></PropuestaMo>
                      <DocumentoIdentidadMo :tipo="tipoCotizacion" v-show="estadoFormCotizacion == 17 && isLogin">
                      </DocumentoIdentidadMo>
                      <VerificacionDatosMo v-if="estadoFormCotizacion == 18 && isLogin"></VerificacionDatosMo>
                      <CargaDocumentoMo v-show="isLogin && estadoFormCotizacion == 19"></CargaDocumentoMo>
                      <RenunciaSeguroMo v-if="estadoFormCotizacion > 4" v-show="estadoFormCotizacion == 20">
                      </RenunciaSeguroMo>
                      <TerminosCondicionesMo :tipo="tipoCotizacion" v-show="estadoFormCotizacion == 21"
                        v-if="estadoFormCotizacion > 8 && isLogin"></TerminosCondicionesMo>
                      <FelicitacionesMo :celular="celular" :tipo="tipoCotizacion" v-show="estadoFormCotizacion == 25"
                        v-if="estadoFormCotizacion > 8 && isLogin"></FelicitacionesMo>

                      <v-btn v-if="estadoFormCotizacion == 5 && !clienteEncontrado" rounded x-large @click="volver"
                        color="#1d2eff"><span><span style="color:white">FINALIZAR</span></span></v-btn>
                      <v-btn v-if="estadoFormCotizacion == 5 && clienteEncontrado" rounded x-large @click="login"
                        color="#1d2eff"><span><span style="color:white">INGRESAR</span></span></v-btn>

                      <!-- <Form1 :paises="paises" :genero="genero" :ocupaciones="ocupacion" v-show="(estadoFormCotizacion == 1 && (tipoPersonaEmpresa==`persona` || tipoPersonaEmpresa==`inicio`))"></Form1>
      <Form2 :ocupaciones="ocupacion" v-show="(estadoFormCotizacion == 2 && tipoPersonaEmpresa==`persona`)"></Form2>
      <Form3 :tipo="tipoCotizacion" v-if="cargaRestricciones" v-show="(estadoFormCotizacion == 3 && tipoCotizacion)"></Form3>
      <Form4 :tipo="tipoCotizacion" v-if="cargaRestricciones" v-show="(estadoFormCotizacion == 4 && tipoCotizacion)"></Form4>
      <FormE1 v-show="estadoFormCotizacion == 1 && tipoPersonaEmpresa==`empresa`"></FormE1>
      <FormE2 :paises="paises" :genero="genero" :ocupaciones="ocupacion" v-show="estadoFormCotizacion == 2 && tipoPersonaEmpresa==`empresa`"></FormE2>
      <ListarPropuesta v-if="estadoFormCotizacion == 5"></ListarPropuesta>      
      <div v-show="(estadoFormCotizacion == 14)" v-for="(bpsitem, i) in bpsList" :key="i">
          <DpsSimulacion v-bind:bps="bpsitem" v-bind:id="(i+1)" v-show="((i+1)==estadoBps)"></DpsSimulacion>
       </div>
      <TerminosCondiciones :tipo="tipoCotizacion" v-show="estadoFormCotizacion == 21" v-if="estadoFormCotizacion > 8 && isLogin"></TerminosCondiciones>
      <ResumenSeguro :tipo="tipoCotizacion" v-show="estadoFormCotizacion == 22 && isLogin"></ResumenSeguro>
      <MediosPago  v-show="estadoFormCotizacion == 8 && isLogin"></MediosPago>
      <Pago  v-show="estadoFormCotizacion == 9 && isLogin"></Pago>
      <Contratacion  v-show="estadoFormCotizacion == 10 && isLogin"></Contratacion>
      <FinContratacion  v-show="estadoFormCotizacion == 11 && isLogin"></FinContratacion>
      <EstadoTransferencia  v-show="estadoFormCotizacion == 12 && isLogin"></EstadoTransferencia>
      <ResumenSeguroCarro v-show="estadoFormCotizacion == 13 && isLogin"></ResumenSeguroCarro>
      <ErrorParrilla  v-show="estadoFormCotizacion == 99"></ErrorParrilla>
      <Propuesta :tipo="tipoCotizacion" v-if="estadoFormCotizacion == 15" ></Propuesta>
      <Devolucion :tipo="tipoCotizacion" v-if="estadoFormCotizacion == 16"></Devolucion>
      <RenunciaSeguro v-if="estadoFormCotizacion > 4" v-show="estadoFormCotizacion == 20 "></RenunciaSeguro>
      <DocumentoIdentidad1  v-show="estadoFormCotizacion == 17 && isLogin"></DocumentoIdentidad1>
      <VerificacionDatos  v-if="estadoFormCotizacion == 18 && isLogin"></VerificacionDatos>
      <CargaDocumento v-show="isLogin && estadoFormCotizacion == 19"></CargaDocumento> -->

                    </v-col>



                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
            </v-row>

          </v-col>
        </v-row>
      </div>
      <Footer></Footer>
      <!-- <AsistenciaSeguro></AsistenciaSeguro> -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import parametros from "../../../services/parametros";
import datausuario from "../../../services/usuario-datos";
import plan from "../../../services/plan";
import Form1Mo from "../mobile/cotizacion/components/Form1Mo.vue";
import Form2Mo from "../mobile/cotizacion/components/Form2Mo.vue";
import Form3Mo from "../mobile/cotizacion/components/Form3Mo.vue";
import Form4Mo from "../mobile/cotizacion/components/Form4Mo.vue";
import FormE1Mo from "../mobile/cotizacion/components/FormE1Mo.vue";
import FormE2Mo from "../mobile/cotizacion/components/FormE2Mo.vue";
import PropuestaMo from "../mobile/cotizacion/components/PropuestaMo.vue";
import FinalCotizacion from "../mobile/cotizacion/components/FinalCotizacion.vue";
import DocumentoIdentidadMo from "../mobile/cotizacion/components/DocumentoIdentidadMo.vue";
import VerificacionDatosMo from "../mobile/cotizacion/components/VerificacionDatosMo.vue";
import CargaDocumentoMo from "../mobile/cotizacion/components/CargaDocumentoMo.vue";
import RenunciaSeguroMo from "../mobile/cotizacion/components/RenunciaSeguroMo.vue";
import TerminosCondicionesMo from "../mobile/cotizacion/components/TerminosCondicionesMo.vue";
import FelicitacionesMo from "../mobile/cotizacion/components/FelicitacionesMo.vue";
import AsistenciaSeguro from "../mobile/cotizacion/components/components/AsistenciaSeguro.vue";
import { useRouter } from "vue-router/composables";

export default {
  name: "navMueve",
  components: {
    Nav: Nav,
    Footer: Footer,
    Form1Mo: Form1Mo,
    Form2Mo: Form2Mo,
    Form3Mo: Form3Mo,
    Form4Mo: Form4Mo,
    FormE1Mo: FormE1Mo,
    FormE2Mo: FormE2Mo,
    PropuestaMo: PropuestaMo,
    FinalCotizacion: FinalCotizacion,
    DocumentoIdentidadMo: DocumentoIdentidadMo,
    VerificacionDatosMo: VerificacionDatosMo,
    CargaDocumentoMo: CargaDocumentoMo,
    RenunciaSeguroMo: RenunciaSeguroMo,
    TerminosCondicionesMo: TerminosCondicionesMo,
    FelicitacionesMo: FelicitacionesMo,
    AsistenciaSeguro: AsistenciaSeguro
  },
  data() {
    return {
      dialogAsistencia: false,
      overlay: false,
      fechaAcceso: "",
      ufActual: "",
      loginEstado: "",
      loginUsuario: "",
      nombrelogin: "",
      rutEmpresa: "",
      celular: "",
      loginEstado: this.$store.state.loginEstado,
      loginUsuario: this.$store.state.loginUsuario,
      clienteEncontrado: this.$store.state.clienteEncontrado,
      estadoFormCotizacion: this.$store.state.estadoFormCotizacion,
      tipoPersonaEmpresa: this.$store.state.tipoPersonaEmpresa,
      tipoCotizacion: this.$store.state.tipoCotizacion,
      restricciones: this.$store.state.restricciones,
      isLogin: this.$store.state.islogin,
      cliente: this.$store.state.cliente,
      paises: { nombre: [], codigo: [] },
      genero: { nombre: [], codigo: [] },
      ocupacion: { nombre: [], codigo: [] },
      cargaRestricciones: false,
      admins: [],
      estadoBps: this.$store.state.estadoBps,
      bpsList: this.$store.state.dps,
      documentosCredito: [],
      documentosPrepago: []
    };
  },
  computed: {

  },

  methods: {
    llamada(){
      window.open("https://atencionvirtual.mueveseguro.cl/contact_button/seller/eSaTemScK7zyNGPp91nQbh8p46sJ8Uw89Vuhwg8P", "");
    },
    asistencia() {
      this.$root.$refs.ModalAsistencia.open();
    },
    login() {
      location.href = "/login";
    },
    volver() {
      location.href = "/";
    },
    async aprobar() {
      this.overlay = true;
      let res = false;
      var simulacion = this.$store.state.simulacion;

      var preparaSimulacion = {
        ctt_simulacion: simulacion.sim_numero,
        dpsRespuestas: []
      }

      res = await plan.aprobar(preparaSimulacion);

      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
        if (res.data.estadoTransaccion.success) {
          this.$store.commit('setcontrato', res.data.contrato);
        }
        this.estadoFormCotizacion = 5;
        // this.$root.$refs.FormularioLinea.estadoFormLinea = 7;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }

      this.overlay = false;
    },
    async traePaises() {
      let res = false;
      this.overlay = true;
      res = await parametros.traepaises();
      if (res.status == 200 && res.data.estadoTransaccion.success) {

        let paises = {
          nombre: [],
          codigo: []
        };
        res.data.tablaPaises.forEach(element => {
          paises.nombre.push(element.pai_nombre);
          paises.codigo.push(element.pai_codigo);
        });

        this.paises = paises;
        this.overlay = false;
        return;
      } else {
        this.$store.commit('setclienteencontrado', false);
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }
    },
    async traeDocumentos(doc) {
      let res = false;
     
      let sw1 = false;
      let sw2 = false;
      let seguro = {
        seg_numero: this.$store.state.seguroSeleccion,
        seg_tipo_docto: 3
      }
      if (doc == 1) {
        this.overlay = true;
        res = await datausuario.listarDocumentos(seguro);
        if (res.status == 200 && res.data.estadoTransaccion.success) {

          this.documentosCredito = res.data.bibliotecaDoctos;
          for (let index = 0; index < this.documentosCredito.length; index++) {
            this.documentosCredito[index].cargado = true;
            this.documentosCredito[index].estadocargado = true;
            this.documentosCredito[index].chosenFile = this.documentosCredito[index].doc_nombre_docto;
          }

          sw1 = true;

          this.overlay = false;
        } else {
          this.overlay = false;
          this.documentosCredito = [{
            dde_numero: 0,
            doc_nombre_docto: "",
            doc_extension: "",
            doc_cabecera: 0,
            doc_cotizacion: 0,
            doc_tipo_docto: 0,
            doc_cargado: 0,
            doc_firmado: 0,
            doc_autorizado: 0,
            doc_glosa_tipo: "",
            cargado: false,
            estadocargado: false,
            chosenFile: null
          }]
          sw1 = false;
        }
        this.$root.$refs.CargaDocumento.documentosCredito = this.documentosCredito;
      }

      
      if (doc == 2) {
        this.overlay = true;
        seguro.seg_tipo_docto = 4;
        res = await datausuario.listarDocumentos(seguro);
        if (res.status == 200 && res.data.estadoTransaccion.success) {

          this.documentosPrepago = res.data.bibliotecaDoctos;
          for (let index = 0; index < this.documentosPrepago.length; index++) {
            this.documentosPrepago[index].cargado = true;
            this.documentosPrepago[index].estadocargado = true;
            this.documentosPrepago[index].chosenFile = this.documentosPrepago[index].doc_nombre_docto;
          }
          this.$root.$refs.CargaDocumento.continuar = true;
          this.overlay = false;
          sw2 = true;
        } else {
          this.overlay = false;
          this.documentosPrepago = [{
            dde_numero: 0,
            doc_nombre_docto: "",
            doc_extension: "",
            doc_cabecera: 0,
            doc_cotizacion: 0,
            doc_tipo_docto: 0,
            doc_cargado: 0,
            doc_firmado: 0,
            doc_autorizado: 0,
            doc_glosa_tipo: "",
            cargado: false,
            estadocargado: false,
            chosenFile: null
          }]
          sw2 = false;
        }
        this.$root.$refs.CargaDocumento.documentosPrepago = this.documentosPrepago;
      }      
     
      if (sw1 || sw2) {
        this.$root.$refs.CargaDocumento.continuar = true;
      } else {
        this.$root.$refs.CargaDocumento.continuar = false;
      }


    },
    async traeGenero() {
      let res = false;
      res = await parametros.traegenero();
      if (res.status == 200 && res.data.estadoTransaccion.success) {

        let genero = {
          nombre: [],
          codigo: []
        };
        res.data.tablaGenero.forEach(element => {
          genero.nombre.push(element.sex_glosa);
          genero.codigo.push(element.sex_numero);
        });

        this.genero = genero;
        return;
      } else {
        this.$store.commit('setclienteencontrado', false);
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }
    },
    async traeRestricciones() {
      let res = false;
      res = await parametros.traerestricciones();
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        this.restricciones = res.data.tablaRectriccion;
        this.$store.commit('setrestricciones', this.restricciones);
        this.cargaRestricciones = true;

        return;
      } else {
        this.$store.commit('setclienteencontrado', false);
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }
    },
    async traeOcupacion() {
      let res = false;
      res = await parametros.traeocupaciones();
      if (res.status == 200 && res.data.estadoTransaccion.success) {

        let ocupacion = {
          nombre: [],
          codigo: []
        };
        res.data.tablaOcupaciones.forEach(element => {
          ocupacion.nombre.push(element.ocu_glosa);
          ocupacion.codigo.push(element.ocu_numero);
        });

        this.ocupacion = ocupacion;
        return;
      } else {
        this.$store.commit('setclienteencontrado', false);
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }
    },
    cerrar: function () {
      this.$store.commit("setislogin", false);
      localStorage.removeItem("loginEstado");
      localStorage.removeItem("loginCliente");
      this.$router.push('/');

    },
    hover: function (name) {
      var el = document.querySelector("." + name);

      el.setAttribute("src", "/playground_assets/rectangle3839484-hdtp.svg");
      el.classList.add("boton-hover");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.setAttribute(
        "src",
        "/playground_assets/CondicionesPersonas/rectangle3821251-ubfb.svg"
      );
      el.classList.remove("boton-hover");
    },
    seleccionTipo: function (tipo, formdisabled = false) {

      if (!formdisabled) {
        this.$root.$refs.CotizaForm3.tipodisabled = false;
        // this.$root.$refs.CotizaForm3Mo.tipodisabled=false;
      }
      // var el = "";
      // var txt = "";
      // var pt = "";
      // el = document.querySelector("." + tipo);
      // txt = document.querySelector(".t" + tipo);
      // pt = document.querySelector(".p" + tipo);
      // el.classList.add("btnTipo");
      // txt.classList.add("btnTxt");
      // pt.classList.add("puntoColor");
      // if (tipo == "vigente") {
      //   el = document.querySelector(".nuevo");
      //   el.classList.remove("btnTipo");
      //   txt = document.querySelector(".tnuevo");
      //   txt.classList.remove("btnTxt");
      //   pt = document.querySelector(".pnuevo");
      //   pt.classList.remove("puntoColor");
      // }
      // if (tipo == "nuevo") {
      //   el = document.querySelector(".vigente");
      //   el.classList.remove("btnTipo");
      //   txt = document.querySelector(".tvigente");
      //   txt.classList.remove("btnTxt");
      //   pt = document.querySelector(".pvigente");
      //   pt.classList.remove("puntoColor");
      // }
      this.$store.commit("settipocotizacion", tipo);
      this.tipoCotizacion = tipo;
      this.estadoFormCotizacion = 3;
      this.$root.$refs.CotizaForm4.tipoCotizacion = tipo;
      // this.$root.$refs.CotizaForm4Mo.tipoCotizacion=tipo;
    },

    seleccionTipoUsuario: function (tipo, formdisabled = false) {
      if (!formdisabled) {
        this.$root.$refs.CotizaForm1.tipodisabled = false;
        // this.$root.$refs.CotizaForm1Mo.tipodisabled=false;
      }

      // var el = "";
      // var txt = "";
      // var pt = "";
      // el = document.querySelector("." + tipo);
      // txt = document.querySelector(".t" + tipo);
      // pt = document.querySelector(".p" + tipo);
      // el.classList.add("btnTipo");
      // txt.classList.add("btnTxt");
      // pt.classList.add("puntoColor");
      // if (tipo == "persona") {
      //   el = document.querySelector(".empresa");
      //   el.classList.remove("btnTipo");
      //   txt = document.querySelector(".tempresa");
      //   txt.classList.remove("btnTxt");
      //   pt = document.querySelector(".pempresa");
      //   pt.classList.remove("puntoColor");
      // }
      // if (tipo == "empresa") {
      //   el = document.querySelector(".persona");
      //   el.classList.remove("btnTipo");
      //   txt = document.querySelector(".tpersona");
      //   txt.classList.remove("btnTxt");
      //   pt = document.querySelector(".ppersona");
      //   pt.classList.remove("puntoColor");
      //   console.log(tipo);
      // }
      this.$store.commit("settipopersonaempresa", tipo);
      this.tipoPersonaEmpresa = tipo;
      this.estadoFormCotizacion = 1;
    },
    formatDate: function (date) {
      if (!date) return null

      var arr = date.split('-'),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}/${month}/${year}`
    },
    cargaDps() {
      this.bpsList = this.$store.state.dps;
      this.$store.commit("setcontdps", this.bpsList.length);
      this.validrut = true;
    },
  },
  mounted() {
    this.$store.commit('setcontratarlinea', false);
    this.traePaises();
    this.traeRestricciones();
    this.traeGenero();
    this.traeOcupacion();
    
    if (!localStorage.getItem('loginEstado')) {
      this.isLogin = false;
      this.$store.commit("setislogin", false);
      localStorage.removeItem('loginEstado');
      localStorage.removeItem('loginCliente');
      this.$store.commit("setestadoseguro", 0);
    } else {
      this.$store.commit("setislogin", true);
      this.isLogin = true;
      this.loginEstado = JSON.parse(localStorage.getItem('loginEstado'));
      this.loginUsuario = JSON.parse(localStorage.getItem('loginUsuario'));
      this.fechaAcceso = this.loginUsuario.acc_fecha_ingreso;
      this.ufActual = this.loginUsuario.acc_uf;
      this.rutEmpresa = this.loginUsuario.contrato.ctt_rut_contrato + "-" + this.loginUsuario.contrato.ctt_dv_contrato;
      this.nombrelogin = this.loginUsuario.acc_nombre_principal + " " + this.loginUsuario.acc_ape_paterno;
      this.$store.commit("setislogin", true);
      this.$store.commit("setdatoscontrato", this.loginUsuario.contrato);
      this.isLogin = true;

      var form1 = {
        rut: this.loginUsuario.contrato.ctt_rut_representante + "-" + this.loginUsuario.contrato.ctt_dv_representante,
        nombre1: this.loginUsuario.acc_nombre_principal,
        nombre2: this.loginUsuario.acc_nombre_segundo,
        apaterno: this.loginUsuario.acc_ape_paterno,
        amaterno: this.loginUsuario.acc_ape_materno,
        nacionalidad: this.loginUsuario.contrato.per_pais,
        genero: this.loginUsuario.contrato.per_genero,
      };
      var form2 = {
        email: this.loginUsuario.contrato.per_email,
        celular: this.loginUsuario.contrato.per_celular,
        date: this.loginUsuario.contrato.per_fec_nac,
        ocupacion: this.loginUsuario.contrato.per_ocupacion,
      }
      this.celular = this.loginUsuario.contrato.per_celular;
      var forme1 = {
        rut: this.loginUsuario.contrato.ctt_rut_contrato + "-" + this.loginUsuario.contrato.ctt_dv_contrato,
        nombre1: this.loginUsuario.contrato.per_nombre,
        telefono: this.loginUsuario.contrato.per_fono,
        email: this.loginUsuario.contrato.per_email,
      }
      var forme2 = {
        rut: this.loginUsuario.contrato.ctt_rut_representante + "-" + this.loginUsuario.contrato.ctt_dv_representante,
        nombre1: this.loginUsuario.acc_nombre_principal,
        nombre2: this.loginUsuario.acc_nombre_segundo,
        apaterno: this.loginUsuario.acc_ape_paterno,
        amaterno: this.loginUsuario.acc_ape_materno,
        nacionalidad: this.loginUsuario.contrato.per_pais,
        genero: this.loginUsuario.contrato.per_genero,
        ocupacion: this.loginUsuario.contrato.per_ocupacion,
      };
      forme2.date = this.loginUsuario.contrato.per_fec_nac;
      this.$store.state.estadoCliente.tiene_linea_desgravamen
      var estadoCliente = {
        seguro_estado: 0,
        tiene_contrato: 0,
        tiene_login: 0,
        tiene_linea_desgravamen: 1,
        tiene_seguro_contratado: 0,
        esta_lista_negra: 0,
        texto_principal: "",
        texto_secundario: ""
      };
      var tipo = "persona"
      if (this.loginUsuario.contrato.ctt_tipo_cliente == 2) {
        tipo = "empresa"
      }
      this.$store.commit('settipopersonaempresa', tipo);
      this.$store.commit('setestadocliente', estadoCliente);
      this.$store.commit('setform1', form1);
      this.$store.commit('setform2', form2);
      this.$store.commit('setforme1', forme1);
      this.$store.commit('setforme2', forme2);

      
      this.estadoFormCotizacion = this.$store.state.estadoFormCotizacionPrivado;
    }
    window.scrollTo(0, 0);
    // this.cargaDps();
  },
  created() {
    this.$root.$refs.CotizaForm = this;
  },

  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>


<style scoped>
.fullh {
  /* height:100%; */
}

.fixfooter {
  bottom: 0;
  position: fixed;
}

.bannerlogo {
  background-image: url('/assets/cotizacion/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 0px !important;
  height: 100%;
}

.colcenter {
  display: flex;
  justify-content: center;
}

.colleft {
  display: flex;
  justify-content: left;
}

.banner-principal2 {
  background: var(--blanco, #f0f2f2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* width: 100%; */
  /* height: 200px; */
  position: relative;
  /* left: 0px;
  top: 966px; */
  overflow: hidden;
  padding: 20px;
}

.frame-4 {
  padding: 0px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 232px;
  position: relative;
}

.frame-29 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.una-empresa-de {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 160px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logotipo-grupo-insurex-blanco-1 {
  flex-shrink: 0;
  width: 200px;
  height: 75.25px;
  position: relative;
  object-fit: cover;
}

.frame-7 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 720px;
  position: relative;
  overflow: hidden;
}

.frame-2 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 720px;
  position: relative;
}

.respaldados-por {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-13 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.dise-o-sin-t-tulo-27-1 {
  flex-shrink: 0;
  width: 168.48px;
  height: 91.5px;
  position: relative;
  object-fit: cover;
}

.dise-o-sin-t-tulo-29-1 {
  flex-shrink: 0;
  width: 324.72px;
  height: 68.26px;
  position: relative;
  object-fit: cover;
}

.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 236px;
}

.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}


.formulario-cotiza-persona-1 {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  padding: 20px 80px 20px 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-bottom: 20px;
}

.hola-nombre-test {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  /* font-size: var(--headings-h2-font-size, 42px); */
  font-size: clamp(1.2rem, 5vw, 2rem);
  line-height: clamp(1.4rem, 5vw, 2rem);
  /* line-height: var(--headings-h2-line-height, 50px); */
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}

.hola-nombre-test2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 38px);
  line-height: var(--headings-h2-line-height, 46px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}

.te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h5-font-size, 24px); */
  font-size: clamp(0.7rem, 5vw, 1.3rem);
  line-height: clamp(0.7, 5vw, 1.4);
  /* line-height: var(--headings-h5-line-height, 28px); */
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
}

.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

.frame-32 {
  background: #d9d9d9;
  border-radius: 80px;
  padding: 9px 11px 9px 11px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.persona {
  flex-shrink: 0;
  width: 171px;
  height: 40px;
  position: relative;
}

.rectangle-33 {
  background: var(--azul-complementario, #04137f);
  border-radius: 80px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
}

.persona2 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 8.19%;
  left: 5.85%;
  width: 85.96%;
  bottom: 15%;
  top: 17.5%;
  height: 67.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empresa {
  flex-shrink: 0;
  width: 171px;
  height: 40px;
  position: relative;
}

.rectangle-34 {
  border-radius: 80px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
}

.empresa2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 7.02%;
  left: 7.02%;
  width: 85.96%;
  bottom: 15%;
  top: 17.5%;
  height: 67.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formulario-cotiza {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  padding: 12px 20px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.frame-12 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.group-25 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}

.group-20 {
  width: 20px;
  height: 20px;
  position: static;
}

.vector {
  width: 20%;
  height: 100%;
  position: absolute;
  right: 60%;
  left: 20%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}

._1 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 87.73%;
  left: 6.82%;
  width: 5.45%;
  bottom: 13.64%;
  top: 13.64%;
  height: 72.73%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-26 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}

.group-23 {
  width: 20px;
  height: 20px;
  position: static;
}

.vector2 {
  width: 20%;
  height: 100%;
  position: absolute;
  right: 20%;
  left: 60%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}

._2 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 46.25%;
  left: 46.25%;
  width: 7.5%;
  bottom: 21.88%;
  top: 18.75%;
  height: 59.38%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-27 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}

.group-24 {
  width: 20px;
  height: 20px;
  position: static;
}

.vector3 {
  width: 20%;
  height: 100%;
  position: absolute;
  right: -20%;
  left: 100%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}

._3 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 7.73%;
  left: 86.82%;
  width: 5.45%;
  bottom: 13.64%;
  top: 13.64%;
  height: 72.73%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formstyle {
  /* padding: 20px; */
}

.personaBtn {

  background-color: #04137f;
}

.personaBtn-s {

  color: white;
}

.personaBtn2 {

  background-color: transparent;
}

.personaBtn2-s {

  color: #04137f;
}

.empresaBtn {

  background-color: #04137f;
}

.empresaBtn-s {

  color: white;
}

.empresaBtn2 {

  background-color: transparent;
}

.empresaBtn2-s {

  color: #04137f;
}
.videollamada{
  border-radius: 50px;
  cursor: pointer;
  width: 320px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 800px) {
  .sm-size {
    font-size: xx-small !important;
  }

  .footer {
    position: relative;
  }

  .formulario-cotiza-persona-1 {
    padding: 20px 10px 20px 10px !important;
  }

  .frame-32 {
    padding: 5px 8px 5px 8px;
  }

  .asistente {
    justify-content: center !important;
  }

}

@media only screen and (max-width: 1270px) {
  .sm-size {
    font-size: small;
  }

  .bannerlogo {
    background-size: 100% 100% !important;
  }

}
</style>