<template>
  <v-row class="colcenter">
    <v-col cols="12" sm="12" md="9" lg="9" xl="9">
      <v-row class="imagenBienvenido">
        <v-col cols="12" sm="12" md="7" lg="7" xl="7">
          <div class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones">
            <span>
              <span
                class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span">
                Bienvenido a tu Mi Portal Seguro
                <br>
              </span>
              <span
                class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2">
                <br>
                Para continuar con tu PORTABILIDAD
                <br>
                accede a MIS SIMULACIONES
              </span>
            </span>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="bannerBienvenido">
          <img class="happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1"
            src="/assets/privado/happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-10.png" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">

    </v-col>
    <v-col cols="11" sm="11" md="11" lg="8" xl="8" v-for="(seguro, i) in Seguros"
      :class="{ 'fondo-seguros': seguro.cse_estado == '9', 'fondo-seguros-aviso': seguro.cse_estado != '9' }"
      class="colcenter  mb-5">
      <v-row class="colcenter" :class="{ 'aviso-cuadro': seguro.cse_estado != '9' }" >
        <v-col cols="12" sm="12" md="11" lg="10" xl="10" class="colcenter cuadros aviso" v-if="seguro.cse_estado != 9">
          <v-row class="colcenter">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="seg-desgravamen-aviso1 colcenter">
                Estimado cliente
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="seg-desgravamen-aviso2 colcenter">
                Una vez que su aseguradora actual confirme la renuncia de tu seguro actual, recibirás tu nueva póliza.
                Mientras tanto, tu cobertura actual permanece vigente
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="10" lg="6" xl="6" class="colcenter cuadros" v-if="seguro.cse_estado == 9">
          <v-row class="colcenter">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="seg-desgravamen colcenter">
                <img class="vector6" src="/assets/privado/misseguros/vector5.svg">
                Seguro desgravamen
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Beneficiario:</div><br>
              <div class="banco">{{ seguro.cse_institucion }}</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Número del Crédito:</div><br>
              <div class="banco">{{ seguro.cse_nro_credito_original }}</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Monto Asegurado:</div><br>
              <div class="banco">{{ seguro.cse_sim_saldo_insoluto }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical class="divide" v-if="seguro.cse_estado != 9"></v-divider>
        <v-col cols="12" sm="12" md="10" lg="6" xl="6" class="colcenter cuadros" v-if="seguro.cse_estado == 9">
          <v-row class="colcenter">

            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Compañía:</div><br>
              <div class="banco">{{ seguro.cse_nombre_compania }}</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Valor de prima:</div><br>
              <div class="banco">{{ seguro.cse_sim_monto_prima_cl }}</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">N° Poliza:</div><br>
              <div class="banco">{{ seguro.cse_pln_poliza_individual }}</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
              <div class="beneficiario">Vigencia</div><br>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="frame-32-2">
                <v-row class="colcenter">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <div class="fechadetalle fecha-inicio">
                      <span>
                        <span class="fecha-inicio">
                          Fecha inicio
                          <br>
                        </span>
                        <span class="fecha-inicio-span2">{{ seguro.cse_pln_fecha_inicio }}</span>
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <div class="fechadetalle fecha-inicio">
                      <span>
                        <span class="fecha-inicio">
                          Fecha Termino
                          <br>
                        </span>
                        <span class="fecha-inicio-span2">{{ seguro.cse_pln_fecha_termino }}</span>
                      </span>
                    </div>
                  </v-col>
                </v-row>

              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter">
          <v-row class="colcenter">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="volver"><span><span
                    style="color:white">VOLVER</span></span></v-btn>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                  <v-btn class="sm-size"  rounded x-large @click="continuar" color="#1d2eff"
                      ><span><span style="color:white;">DESCARGAR POLIZA</span></span></v-btn>
              </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
      <div
        class="mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8">
        Mueve Seguro actúa conforme a la&nbsp;Circular 2.114 de fecha 26-07-2013 de la
        CMF,&nbsp;Ley 19.496 Art.&nbsp;17D,&nbsp;Ley 20.448 Art. 8
      </div>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import ItemSeguro from "./ItemSeguro.vue"
export default {
  name: "PrivadoSeguros",
  components: {
    ItemSeguro: ItemSeguro,
  },
  data() {
    return {
      items: [
        "Vigente",
        "No vigente",
        "Inactivo",
        "Vencido",
      ],
      value: "",
      page: 1,
      paginas: 0,
      orden: false,
      pp: 100,
      Seguros: [],
      SegurosOrigen: [],
      datosContrato: {},
    };
  },
  props: {
    montoSaldos: {},
  },
  methods: {
    volver() {
      this.$root.$refs.HomePrivado.cambiaMenu(1);
    },
    cambiaPagina(value) {
      this.Paginacion(value);
    },
    actualizaDatos() {
      this.SegurosOrigen = this.$root.$refs.HomePrivado.misSeguros;
      if (!this.SegurosOrigen.length) {
        this.Seguros = [];
        this.page = 0;
        this.paginas = 0;
      } else {
        this.Paginacion(this.page);
      }

    },
    ordena() {
      this.$root.$refs.HomePrivado.overlay = true;
      var arraySeguros = this.SegurosOrigen;
      var arraySeg = [];
      console.log(arraySeguros);
      setTimeout(() => {
        if (this.orden) {
          this.orden = false;
          arraySeg = _.orderBy(arraySeguros, 'plan', 'asc');
          console.log(arraySeg);
          console.log("primero");
        } else {
          this.orden = true;
          arraySeg = _.orderBy(arraySeguros, 'plan', 'desc');
          console.log(arraySeg);
          console.log("segundo");
        }
        this.Seguros = arraySeg;
        // this.Paginacion(this.page);
        this.$root.$refs.HomePrivado.overlay = false;
      }, 300);
    },

    formatNumberES(n, d = 0) {
      n = new Intl.NumberFormat("es-ES").format(parseFloat(n).toFixed(d));
      if (d > 0) {
        // Obtenemos la cantidad de decimales que tiene el numero
        const decimals =
          n.indexOf(",") > -1 ? n.length - 1 - n.indexOf(",") : 0;

        // añadimos los ceros necesios al numero
        n =
          decimals == 0
            ? n + "," + "0".repeat(d)
            : n + "0".repeat(d - decimals);
      }
      return n;
    },
    Paginacion(np) {
      // np--;
      // this.$root.$refs.HomePrivado.overlay=true;
      // setTimeout(() => {           
      // this.Seguros=[];
      // this.paginas=0;
      // var seguros=this.SegurosOrigen;
      // var reg= seguros.length;
      // var rpage= reg % this.pp;
      // var paginas= Math.trunc(reg/this.pp);
      // if(rpage){
      //     paginas++;
      // }
      // this.paginas=paginas;    
      // var ini=this.pp*np; 
      // var corte=(this.pp-1)+ini;
      // for (let i = ini; i < seguros.length; i++) {
      //     if(i>corte){
      //         break;
      //     }
      //     this.Seguros.push(seguros[i]);           
      // }
      // this.$root.$refs.HomePrivado.overlay=false;
      // }, 500);

      np--;
      this.$root.$refs.HomePrivado.overlay = true;
      setTimeout(() => {
        var seguros = this.SegurosOrigen;
        this.Seguros = [];
        this.paginas = 0;
        var reg = seguros.length;
        var rpage = reg % 100;
        var paginas = Math.trunc(reg / 100);
        if (rpage) {
          paginas++;
        }
        this.paginas = paginas;
        if (np >= this.paginas) {
          this.page = np;
          np--;
        }
        var ini = 100 * np;
        var corte = 100 + ini - 1;

        for (let i = ini; i < seguros.length; i++) {
          if (i > corte) {
            break;
          }
          this.Seguros.push(seguros[i]);
        }
        console.log(this.Seguros);
        this.$root.$refs.HomePrivado.overlay = false;
      }, 500);
    }
  },
  mounted() {
    this.SegurosOrigen = this.$root.$refs.HomePrivado.misSeguros;
    if (this.SegurosOrigen.length > 0) {
      this.Paginacion(1);
    } else {
      this.Paginacion(0);
    }

    //  var styleElement = document.createElement("style");
    //   styleElement.appendChild(document.createTextNode("div ::-webkit-scrollbar {-webkit-appearance: none;width: 20px;}div ::-webkit-scrollbar-thumb {border-radius: 10px;background-color: rgba(113, 48, 237, 1);-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);}"));
    //   document.getElementsByTagName("head")[0].appendChild(styleElement);

  },
  created() {
    this.$root.$refs.PrivadoSeguros = this;
  }

};
</script>
<style scoped>
.divide {
  display: inline-flex;
}

.sm-size {
  width: 50%;
}

.colcenter {
  display: flex;
  justify-content: center;
}

.imagenBienvenido {
  background-color: white;
  border-radius: 40px;
}

.bannerBienvenido {
  padding: 0px;
}

.rectangle-432 {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  flex-shrink: 0;
  width: 90%;
  height: 270px;
  position: relative;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cuadros {
  padding: 10px;
}
.aviso-cuadro{
  background-color: black; 
  border-radius: 20px;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
    top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 42px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.si-tienes-otros-cr-ditos-de-consumo-automotriz-o-comercial-pyme-accede-a-contratar-seguro-para-portarlo-y-recuperar-dinero {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 1000%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

.txtbnt7 {
  font-size: 26px !important;
  font-weight: 500;
  padding: 28px;
  /* padding-top: 28px !important;
  padding-bottom: 28px !important; */
  /* background-color: rgb(3 75 178) !important;  */
  color: rgba(4, 19, 127, 1) !important;

}

.txtbnt6 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: white !important;
  /* color: white !important; */
  text-transform: unset !important;

}

.aviso {
  height: 280px;
}



@media only screen and (max-width: 1000px) {
  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
    font-size: 17px !important;
  }

  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
    font-size: 23px !important;
  }
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 35px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1 {
  border-radius: 0px 40px 40px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* height: 220px; */
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.seg-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  /* font-size: var(--headings-h3-font-size, 40px);
  line-height: var(--headings-h3-line-height, 48px); */
  font-size: clamp(1rem, 4vw, 2.2rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
  /* height: 40px; */
}

.seg-desgravamen-aviso1 {
  color: var(--azul-complementario, #ffffff);
  text-align: center;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: clamp(1.5rem, 4vw, 2.8rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
}

.seg-desgravamen-aviso2 {
  color: var(--azul-complementario, #ffffff);
  text-align: center;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: clamp(0.8rem, 4vw, 1.5rem);
  line-height: clamp(0.8rem, 4vw, 1.7rem);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  align-self: center;
  margin-top: -50px;
}

.vector6 {
  width: 33px;
  height: 33px;
  position: relative;
  overflow: visible;
  align-self: center;
  margin-right: 10px;
  margin-top: -4px;
}

.fondo-seguros {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 40px;
  padding: 40px 55px 40px 55px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fondo-seguros-aviso {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 40px;
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.beneficiario {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 27px); */
  font-size: clamp(0.8rem, 4vw, 2rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
}

.banco {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: "CircularPro-Black", sans-serif;
  /* font-size: 36px;
  line-height: 27px; */
  font-size: clamp(0.8rem, 4vw, 1.6rem);
  line-height: clamp(0.8rem, 4vw, 1.6rem);
  font-weight: 800;
  position: relative;
}

.colleft {
  display: flex;
  justify-content: left;
}

.fecha-inicio {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
}

.fecha-inicio-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.fechadetalle {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 100px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  width: 100%;
  /* height: 69px; */
  position: relative;
  padding: 3px 20px 3px 20px;

}

@media only screen and (max-width: 800px) {
  .divide {
    display: none !important;
  }

  .sm-size {
    font-size: small !important;
    width: 100%;
  }

  .vector6 {
    margin-right: 10px;
  }

}

@media only screen and (max-width: 1270px) {
  .divide {
    display: none !important;
  }

}
</style>
