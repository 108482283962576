<template>
    <div>
        <v-dialog v-model="dialogSeguro" class="modalSize" max-width="100%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">
                                    Documentos Simulación nro. {{ this.seguro }}
                                </div>

                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                <v-btn rounded color="#04137f" @click="agregaBtn()">
                                    <v-icon left color="white">
                                        mdi-plus-circle
                                    </v-icon>
                                    <span class="btncarga">Archivo</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter scrollh">
                                <v-row class="colcenter">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                                        <template>
                                            <v-data-table :headers="headerdoc" :items="documentos" :items-per-page="20"
                                                class="elevation-1">
                                                <template v-slot:item.doc_autorizado="{ item }" class="colcenter ">
                                                    <v-icon class="ml-5 colcenter" v-if="item.doc_autorizado" color="success">
                                                        mdi-check-circle-outline
                                                    </v-icon>

                                                </template>
                                                <template v-slot:item.accion="{ item }" >
                                                    <v-icon class="mr-2 cursor" color="primary" @click="descargaDocumento(item.dde_numero)" style="margin-top: 3px;">
                                                        mdi-download-outline
                                                    </v-icon>
                                                    <v-icon class="mr-2 cursor " color="red" v-if="!item.doc_autorizado"  @click="eliminaBtn(item.dde_numero)">
                                                        mdi-delete-outline
                                                    </v-icon>

                                                </template>

                                            </v-data-table>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <v-row class="colcenter">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <v-btn class="sm-size" rounded x-large color="#1d2eff"
                                            @click="continuaOrigen"><span><span
                                                    style="color:white;">REGRESAR</span></span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-dialog v-model="dialogSeguroNuevo" class="modalSize" max-width="100%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">
                                    SUBIR DOCUMENTO
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter ">
                                <v-row class="cargar-archivos">
                                    <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                        <v-select 
                                        v-model="select"
                                        :items="tipos"
                                        item-text="nombre"
                                        item-value="valor"
                                        label="Tipo Documento"
                                        persistent-hint
                                        return-object
                                        dense
                                        single-line                                   
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                                        <v-file-input v-model="fl1.chosenFile"
                                            prepend-icon="mdi-upload" label="Seleccionar archivo"
                                            accept="application/pdf, .png,.jpeg,.jpg" @change="cambia()"
                                            dense></v-file-input>
                                        <span class="panell4" v-if="fl1.estadocargado">{{ fl1.chosenFile }}</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="colcenter ">
                                        <v-btn small rounded v-if="fl1.cargado && fl1.chosenFile && fl1.estadocargado"
                                            color="#04137f">
                                            <v-icon v-if="(fl1.cargado && fl1.chosenFile && fl1.estadocargado)" left
                                                color="success">
                                                mdi-check-circle
                                            </v-icon>
                                            <span class="btncarga">Listo</span>
                                        </v-btn>
                                        <v-btn rounded v-if="!fl1.estadocargado" :disabled="!fl1.chosenFile || !select"
                                            color="#04137f" @click="guardarDocumento(select.valor)">
                                            <v-icon v-if="(fl1.cargado && fl1.chosenFile) || fl1.estadocargado" left
                                                color="success">
                                                mdi-check-circle
                                            </v-icon>
                                            <span class="btncarga">Cargar</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <v-row class="colcenter">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <v-btn class="sm-size" rounded x-large color="#1d2eff"
                                            @click="continuaOrigen2"><span><span
                                                    style="color:white;">REGRESAR</span></span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-overlay :value="overlay" class="over">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
import fileservice from "../../../../../services/fileservice.js";
export default {
    name: "ArchivosSeguro",
    data() {
        return {
            overlay: false,
            dialogSeguro: false,
            dialogSeguroNuevo: false,
            select: false,
            fl1: {
                dde_numero: 0,
                doc_nombre_docto: "",
                doc_extension: "",
                doc_cabecera: 0,
                doc_cotizacion: 0,
                doc_tipo_docto: 0,
                doc_cargado: 0,
                doc_firmado: 0,
                doc_autorizado: 0,
                doc_glosa_tipo: "",
                cargado: false,
                estadocargado: false,
                chosenFile: null
            },
            headerdoc: [
                {
                    text: "Nro.",
                    align: "start",
                    sortable: true,
                    value: "dde_numero",
                    color: "#7130ED",
                },
                {
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "doc_nombre_docto",
                    color: "#7130ED",
                },
                { text: "tipo", value: "doc_glosa_tipo" },
                { text: "autorizado", value: "doc_autorizado" },
                { text: "Acción", value: "accion" },
            ],
            documentos: []
        }
    },
    props: {
        seguro: 0,
        tipos: []
    },
    methods: {
        cambia() {
            this.fl1.cargado = false;
            if(this.fl1.chosenFile && (this.fl1.chosenFile.type == "application/pdf" ||  
            this.fl1.chosenFile.type == ".png" || this.fl1.chosenFile.type==".jpeg" ||
            this.fl1.chosenFile.type=="image/jpeg" || this.fl1.chosenFile.type=="image/png")){
                
            }else{
                this.fl1 = {
                dde_numero: 0,
                doc_nombre_docto: "",
                doc_extension: "",
                doc_cabecera: 0,
                doc_cotizacion: 0,
                doc_tipo_docto: 0,
                doc_cargado: 0,
                doc_firmado: 0,
                doc_autorizado: 0,
                doc_glosa_tipo: "",
                cargado: false,
                estadocargado: false,
                chosenFile: null
            } ;
            }           
            
        },
        agregaBtn() {
            this.select = false;
            this.fl1= {
                dde_numero: 0,
                doc_nombre_docto: "",
                doc_extension: "",
                doc_cabecera: 0,
                doc_cotizacion: 0,
                doc_tipo_docto: 0,
                doc_cargado: 0,
                doc_firmado: 0,
                doc_autorizado: 0,
                doc_glosa_tipo: "",
                cargado: false,
                estadocargado: false,
                chosenFile: null
            };
            this.dialogSeguroNuevo = true;
        },
        continuaOrigen() {
            this.dialogSeguro = false;
        },
        continuaOrigen2() {
            this.dialogSeguroNuevo = false;
        },
        async guardarDocumento(doc) {
            this.overlay = true;
            var res = false;
            var filetmp = false;
            filetmp = this.fl1.chosenFile;
            this.dialogSeguro=false;
            this.dialogSeguroNuevo=false;

            var documento = {
                file: filetmp,
                seg_numero: this.seguro,
                cdo_tipo_docto: doc
            };
            res = await fileservice.GuardaIdentidad(documento);
            if (res.status == 200) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.guardado = true;
                this.overlay = false;
                this.fl1.cargado = true;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro);
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
                this.guardado = false;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro);
            }           
           
        },
        // async verDocumento() {
        //     this.overlay = true;
        //     var res = false;
        //     var mandato = false;
        //     var ext = "";
        //     var seguro = {
        //         seg_numero: this.seguro,
        //     };
        //     res = await datausuario.traeMandato(seguro);
        //     if (res.status == 200) {
        //         // this.$alertas(res.data.estadoTransaccion.errors, "success");
        //         mandato = res.data.cdo_docto;
        //         this.numero_mandato = res.data.cdo_numero;
        //         ext = res.data.cdo_extension.toLowerCase();
        //         this.extension = ext;
        //         this.mandato = mandato;
        //         this.$store.commit("setmandato", mandato);
        //         this.overlay = false;
        //         // if (opt) {
        //         //   this.dialog = true;
        //         // }
        //         const binaryString = atob(mandato);
        //         const uint8Array = new Uint8Array(binaryString.length);
        //         for (let i = 0; i < binaryString.length; i++) {
        //             uint8Array[i] = binaryString.charCodeAt(i);
        //         }
        //         const blob = new Blob([uint8Array], { type: "application/pdf" });

        //         this.chosenFile1 = blob;

        //         //   console.log(blob);
        //         const url = URL.createObjectURL(blob);
        //         const a = document.createElement("a");
        //         a.href = url;
        //         a.download = "prueba.pdf";
        //         document.body.appendChild(a);
        //         a.click();
        //         document.body.removeChild(a);
        //         URL.revokeObjectURL(url);
        //     } else {
        //         this.$alertas("En este momento no podemos atender su solicitud", "warning");
        //         this.overlay = false;
        //     }
        // },
        eliminaBtn(dde_numero) {
            this.eliminaDocumento(dde_numero);
        },
        async eliminaDocumento(dde_numero) {
            this.overlay = true;
            this.dialogSeguro = false;
            var ext = "";
            var res = false;
            var seguro = {
                dde_numero: dde_numero
            };
            res = await datausuario.eliminarDocumento(seguro);
            if (res.status == 200) {
                this.overlay = false;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro)
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro)
            }
           
            
        },
        async descargaDocumento(dde_numero) {
            this.overlay = true;
            this.dialogSeguro = false;
            var res = false;
            var seguro = {
                dde_numero: dde_numero
            };
            res = await datausuario.descargaDocumentos(seguro);
            if (res.status == 200) {
                let ext = res.data.cdo_extension.toLowerCase();         
                const binaryString = atob(res.data.cdo_docto);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });
                if(ext==".jpg"){
                    const blob = new Blob([uint8Array], { type: "image/jpeg" });
                }
                if(ext==".png"){
                    const blob = new Blob([uint8Array], { type: "image/png" });
                }
                
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = res.data.cdo_nombre_docto;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                this.overlay = false;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro)
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
                this.$root.$refs.PrivadoCotizacion.traeDocumentos();
                this.$root.$refs.PrivadoCotizacion.documentoSimulacion(this.$root.$refs.PrivadoCotizacion.numeroSeguro)
            }
        },
        cierra() {
            this.dialogSeguro = false;
        },
    },
    mounted() {

    },
    created() {
        this.$root.$refs.ModalArchivosSeguro = this;
    }
}
</script>
<style scoped>
.btncarga {
    color: white;
}
.panell {
    border-radius: 25px 25px 0px 0px !important;
}

.panell2 {
    border-radius: 40px !important;
    /* margin-bottom: 25px  !important; */
    background-color: #F0F2F2 !important;
}

.panell3 {
    /* min-height: 83px; */
    border: 1px solid !important;
    border-radius: 40px !important;
    background-color: white !important;
    color: #04137F;
    font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
    font-size: var(--headings-h7-font-size, 14px);
    line-height: var(--headings-h7-line-height, 17px);
    font-weight: var(--headings-h7-font-weight, 450);
}

.panell4 {
    margin-top: 10px;
    color: #04137F;
    font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
    font-size: var(--headings-h7-font-size, 14px);
    line-height: var(--headings-h7-line-height, 24px);
    font-weight: var(--headings-h7-font-weight, 450);
}

.cargar-archivos {
    background: #ffffff;
    border-radius: 47.5px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: row;
    /* gap: 40px; */
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    width: 100%;
}

.over {
    z-index: 1000;
}

.cursor {
    cursor: pointer;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 70%;
}

.marcoDialog {
    background: var(--blanco, #f0f2f2);

}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: visible;
}

.el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.7rem);
    position: relative;
    width: 100%;
}

.devoluci-n-aproximada-de-prima-no-consumida {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 3vw, 1.1rem);
    line-height: clamp(1rem, 3vw, 1.1rem);
    position: relative;
}

.valores {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 36px;
    line-height: 36px; */
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.6rem);
    font-weight: 900;
    position: relative;

}

.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem, 5vw, 1.8rem);
    line-height: clamp(1.3rem, 5vw, 1.8rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    width: 100%;
}

.scrollh {
    max-height: 400px;
    overflow: scroll;
}

@media only screen and (max-width: 800px) {
    .dialogStyle {
        width: 100% !important;
    }

    .vector {
        width: 20px !important;
        height: 20px !important;
    }

    .scrollh {
        height: 400px !important;
        overflow-y: auto !important;
        ;
    }
}
</style>