<template>
    <v-row class="colcenter ">

        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="cuadros">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="frame-60">
                    <div class="hoja-resumen-del-cr-dito">ANTECEDENTES DEL CREDITO ORIGINAL VIGENTE</div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="frame-61">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="carga_doc"
                            v-for="(fl1, idx1) in documentosCredito" :key="idx1">
                            <v-row class="cargar-archivos">
                                <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                                    <v-file-input v-model="fl1.chosenFile" v-if="!fl1.cargado" prepend-icon="mdi-upload"
                                        label="Seleccionar archivo" accept="application/pdf, .png,.jpeg,.jpg"
                                        @change="cambia1(idx1)" dense></v-file-input>
                                    <span class="panell4" v-if="fl1.estadocargado">{{ fl1.chosenFile }}</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="colcenter ">
                                    <v-btn small rounded v-if="fl1.cargado && fl1.chosenFile && fl1.estadocargado"
                                        color="#04137f">
                                        <v-icon v-if="(fl1.cargado && fl1.chosenFile && fl1.estadocargado)" 
                                            color="success">
                                            mdi-check-circle
                                        </v-icon>
                                        
                                    </v-btn>
                                    <v-btn small rounded v-if="!fl1.estadocargado" :disabled="!fl1.chosenFile"
                                        color="#04137f" @click="guardarDocumento(3, idx1,1)">
                                        <v-icon v-if="(fl1.cargado && fl1.chosenFile) || fl1.estadocargado" left
                                            color="success">
                                            mdi-check-circle
                                        </v-icon>
                                        <span class="btncarga">Cargar</span>
                                    </v-btn>
                                    <v-btn @click="eliminaBtn(1, idx1)"  class="ml-1 mr-4"
                                        fab x-small color="white" elevation="0">
                                        <v-icon color="#d70f0f">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                            <v-btn small rounded color="#04137f" @click="agregaBtn(1)">
                                <v-icon left color="white">
                                    mdi-plus-circle
                                </v-icon>
                                <span class="btncarga">Archivo</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(item) in preguntas1" class="panell2">
                                    <v-expansion-panel-header class="panell3">
                                        {{ item.pfr_pregunta }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content style="text-align: left;" class="panell4">
                                        {{ item.pfr_respuesta }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="cuadros">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="frame-60">
                    <div class="hoja-resumen-del-cr-dito">ANTECEDENTES DEL SEGURO VIGENTE </div><br>
                    <div class="hoja-resumen-del-cr-dito"></div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="frame-61">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="carga_doc"
                            v-for="(fl2, idx2) in documentosPrepago" :key="idx2">
                            <v-row class="cargar-archivos">
                                <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                                    <v-file-input v-model="fl2.chosenFile" v-if="!fl2.cargado" prepend-icon="mdi-upload"
                                        label="Seleccionar archivo" accept="application/pdf, .png,.jpeg,.jpg"
                                        @change="cambia2(idx2)" dense></v-file-input>
                                    <span class="panell4" v-if="fl2.estadocargado">{{ fl2.chosenFile }}</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="colcenter ">
                                    <v-btn small rounded v-if="fl2.cargado && fl2.chosenFile && fl2.estadocargado" 
                                        color="#04137f">
                                        <v-icon v-if="fl2.cargado && fl2.chosenFile && fl2.estadocargado" 
                                            color="success">
                                            mdi-check-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn small rounded v-if="!fl2.estadocargado" :disabled="!fl2.chosenFile"
                                        color="#04137f" @click="guardarDocumento(4, idx2,2)">
                                        <v-icon v-if="(fl2.cargado && fl2.chosenFile) || fl2.estadocargado" left
                                            color="success">
                                            mdi-check-circle
                                        </v-icon>
                                        <span class="btncarga">Cargar</span>
                                    </v-btn>
                                    <v-btn @click="eliminaBtn(2, idx2)"  class="ml-1 mr-4"
                                        fab x-small color="white" elevation="0">
                                        <v-icon color="#d70f0f">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                            <v-btn small rounded color="#04137f" @click="agregaBtn(2)">
                                <v-icon left color="white">
                                    mdi-plus-circle
                                </v-icon>
                                <span class="btncarga">Archivo</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(item2) in preguntas2" class="panell2">
                                    <v-expansion-panel-header class="panell3">
                                        {{ item2.pfr_pregunta }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content style="text-align: left;" class="panell4">
                                        {{ item2.pfr_respuesta }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="line-1"></div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="frame-32-2">
                <v-row class="colcenter">
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" outlined rounded x-large
                            @click="volver"><span><span>REGRESAR</span></span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" rounded x-large @click="continuarF" color="#1d2eff"
                            :disabled="!continuar"><span><span style="color:white">CONTINUAR</span></span></v-btn>
                    </v-col>
                </v-row>

            </div>
        </v-col>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>

</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
import fileservice from "../../../../../services/fileservice.js";
export default {
    name: "DevolucionSeguro",
    components: {

    },
    data() {
        return {
            overlay: false,
            cargado1: false,
            cargado2: false,
            seguro: this.$store.state.seguroSeleccion,
            preguntasDocumentos: this.$store.state.preguntasDocumentos,
            preguntas1: [],
            preguntas2: [],
            chosenFile1: null,
            chosenFile2: null,
            estadocargado1: false,
            estadocargado2: false,
            file: "",
            documentosCredito: [],
            documentosPrepago: [],
            continuar: false
        }
    },
    props: {
        renuncia: 0,
    },
    methods: {
        volver() {
            if (this.$store.state.estadoPrivado) {
                window.history.back();
            } else {
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 17;
            }

        },
        continuarF() {
            // this.$router.push("/mi-cuenta");
            this.$root.$refs.CotizaForm.estadoFormCotizacion = 20;
        },
        async guardarDocumento(doc, idx,doc2) {
            this.overlay = true;
            var res = false;
            var filetmp = false;
            if (doc == 3) {
                filetmp = this.documentosCredito[idx].chosenFile;
            }
            if (doc == 4) {
                filetmp = this.documentosPrepago[idx].chosenFile;
            }
            var documento = {
                file: filetmp,
                seg_numero: this.seguro,
                cdo_tipo_docto: doc
            };
            res = await fileservice.GuardaIdentidad(documento);
            if (res.status == 200) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.guardado = true;
                this.overlay = false;
                if (doc == 3) {
                    this.documentosCredito[idx].cargado = true;
                }
                if (doc == 4) {
                    this.documentosPrepago[idx].cargado = true;
                }
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "warning");
                this.overlay = false;
                this.guardado = false;
            }
            this.$root.$refs.CotizaForm.traeDocumentos(doc2);
        },
        eliminaBtn(doc, idx) {
            if (doc == 1 && this.documentosCredito.length) {
                this.eliminaDocumento(this.documentosCredito[idx].dde_numero,doc);
            }
            if (doc == 2 && this.documentosPrepago.length) {
                this.eliminaDocumento(this.documentosPrepago[idx].dde_numero,doc);
            }
        },

        agregaBtn(doc) {
            let sw=false;
            var dcto = {
                dde_numero: 0,
                doc_nombre_docto: "",
                doc_extension: "",
                doc_cabecera: 0,
                doc_cotizacion: 0,
                doc_tipo_docto: 0,
                doc_cargado: 0,
                doc_firmado: 0,
                doc_autorizado: 0,
                doc_glosa_tipo: "",
                cargado: false,
                estadocargado: false,
                chosenFile: null
            };
            if (doc == 1) {
                this.documentosCredito.forEach(element => {
                    if (!element.estadocargado) {
                        sw=true;
                    }
                });
                if(!sw){
                    this.documentosCredito.push(dcto)
                }                
            }
            if (doc == 2) {
                this.documentosPrepago.forEach(element => {
                    if (!element.estadocargado) {
                        sw=true;
                    }
                });
                if(!sw){
                    this.documentosPrepago.push(dcto)
                }  
            }

        },
        async traePreguntas() {
            this.overlay = true;
            var res = false;
            var seguro = {
                seg_numero: this.seguro,
            };
            res = await datausuario.traePreguntas();
            if (res.status == 200) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.preguntasDocumentos = res.data.preguntasDocumento;
                this.preguntas1[0] = this.preguntasDocumentos[0];
                this.preguntas1[1] = this.preguntasDocumentos[1];
                this.preguntas1[2] = this.preguntasDocumentos[2];
                this.preguntas2[0] = this.preguntasDocumentos[3];
                this.preguntas2[1] = this.preguntasDocumentos[4];
                this.preguntas2[2] = this.preguntasDocumentos[5];

                this.$store.commit("setpreguntasdocumentos", res.data.preguntasDocumento);
                this.overlay = false;

            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");

                this.overlay = false;
            }
        },
        async eliminaDocumento(dde_numero,doc) {
            this.overlay = true;
            var res = false;
            var seguro = {
                dde_numero: dde_numero
            };
            res = await datausuario.eliminarDocumento(seguro);
            if (res.status == 200) {
                this.overlay = false;
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
            this.$root.$refs.CotizaForm.traeDocumentos(doc);
        },
        cambia1(idx) {
            if(this.documentosCredito[idx].chosenFile && (this.documentosCredito[idx].chosenFile.type == "application/pdf" ||  
            this.documentosCredito[idx].chosenFile.type == ".png" || this.documentosCredito[idx].chosenFile.type==".jpeg" ||
            this.documentosCredito[idx].chosenFile.type=="image/jpeg" || this.documentosCredito[idx].chosenFile.type=="image/png")){
                
            }else{
                this.documentosCredito[idx].chosenFile=null
            }
            this.documentosCredito[idx].cargado = false;
        },
        cambia2(idx) {
            if(this.documentosPrepago[idx].chosenFile && (this.documentosPrepago[idx].chosenFile.type == "application/pdf" ||  
            this.documentosPrepago[idx].chosenFile.type == ".png" || this.documentosPrepago[idx].chosenFile.type==".jpeg" ||
            this.documentosPrepago[idx].chosenFile.type=="image/jpeg" || this.documentosPrepago[idx].chosenFile.type=="image/png")){
                
            }else{
                this.documentosPrepago[idx].chosenFile=null
            }
            this.documentosPrepago[idx].cargado = false;
        }

    },
    mounted() {

        this.traePreguntas();
        var cargado = this.$store.state.estadoDocumentos;
        var estadoPrepago = this.$store.state.estadoPrepago;
        var estadoCreditoCarga = this.$store.state.estadoCreditoCarga;

        if (cargado) {
            this.cargado1 = true;
            this.estadocargado1 = true;
            this.cargado2 = true;
            this.estadocargado2 = true;
        } else {
            this.cargado1 = false;
            this.estadocargado1 = false;
            this.cargado2 = false;
            this.estadocargado2 = false;
        }
        if (estadoPrepago) {
            this.cargado1 = true;
            this.estadocargado1 = true;
        } else {
            this.cargado1 = false;
            this.estadocargado1 = false;
        }
        if (estadoCreditoCarga) {
            this.cargado2 = true;
            this.estadocargado1 = true;
        } else {
            this.cargado2 = false;
            this.estadocargado1 = false;
        }
    },
    created() {
        this.$root.$refs.CargaDocumento = this;
    },

}
</script>
<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after {
    border-color: rgb(0 0 0 / 0%) !important;
}
.cuadros {
    padding: 20px;
    padding-bottom: 0px;
}

.btncarga {
    color: white;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.doc-st1 {}

.frame-60 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    /* padding: 20px 79px 20px 79px; */
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hoja-resumen-del-cr-dito {
    color: var(--blanco, #f0f2f2);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 20px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hoja-resumen {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    /* height: 394px; */
    position: relative;
}

.frame-61 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.cargar-archivos {
    background: #ffffff;
    border-radius: 47.5px;
    /* padding: 20px; */
    display: flex;
    flex-direction: row;
    /* gap: 40px; */
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    width: 100%;
}

.panell {
    border-radius: 25px 25px 0px 0px !important;
}

.panell2 {
    border-radius: 40px !important;
    /* margin-bottom: 25px  !important; */
    background-color: #F0F2F2 !important;
}

.panell3 {
    /* min-height: 83px; */
    border: 1px solid !important;
    border-radius: 40px !important;
    background-color: white !important;
    color: #04137F;
    font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
    font-size: var(--headings-h7-font-size, 14px);
    line-height: var(--headings-h7-line-height, 17px);
    font-weight: var(--headings-h7-font-weight, 450);
}

.panell4 {
    margin-top: 10px;
    color: #04137F;
    font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
    font-size: var(--headings-h7-font-size, 14px);
    line-height: var(--headings-h7-line-height, 24px);
    font-weight: var(--headings-h7-font-weight, 450);
}

.frame-32-2 {
    /* background: #d9d9d9; */
    border-radius: 80px;
    padding: 9px 20px 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

@media only screen and (max-width: 800px) {
    .cargar-archivos {
        border-radius: 0px !important;
    }

    .cuadros {
        padding: 0px;
        padding-bottom: 20px;
    }

    .sm-size {
        font-size: small !important;
    }

    .base-image-input {
        height: 150px !important;
    }

    .panell3 {
        font-size: var(--headings-h7-font-size, 10px) !important;
    }
}

.carga_doc {
    display: flex;
    justify-content: center;
    padding: 5px 20px;
}
</style>